(function($) {
  $(document).ready(function() {
    // Slides to display (depending on breakpoints)
    var slides_number = $("#content-slider").data("slides-count") || 1;
    var slides_number_992 = (slides_number > 1) ? 2 : 1;
    // Slides available
    var slides_total = $("#content-slider article").length;
    // Activate loop only if there are more slides available
    // than slides to display simultaneously (depending on breakpoints)
    var loop_count = (slides_total > slides_number) ? true : false;
    var loop_count_992 = (slides_total > slides_number_992) ? true : false;
    var loop_count_768 = (slides_total > 1) ? true : false;

    $("#content-slider").lightSlider({
        loop: loop_count,
        keyPress: true,
        item: slides_number,
        speed: 1000, //ms'
        auto: true,
        pauseOnHover: true,
        slideMargin: 20,
        responsive : [
          {
            breakpoint:992,
            settings: {
                item: slides_number_992,
                loop: loop_count_992
              }
          },
          {
            breakpoint:768,
            settings: {
                item: 1,
                loop: loop_count_768
              }
          }
        ]
      });
  });
})(jQuery);

